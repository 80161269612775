body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
  color: #333;
}
header {
  background: #4caf50;
  color: #fff;
  padding: 1rem 0;
  text-align: center;
  position: relative;
}
.logo {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 50px;
  height: 50px;
}
nav {
  background: #333;
  color: #fff;
  padding: 0.5rem 0;
  text-align: center;
}
nav a {
  color: #fff;
  text-decoration: none;
  margin: 0 15px;
  padding: 0.5rem 1rem;
  display: inline-block;
}
nav a:hover {
  background: #4caf50;
  border-radius: 5px;
}
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem 2rem;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* border-radius: 8px; */
}
h1,
h2 {
  color: #4caf50;
}
p {
  color: #666;
  line-height: 1.6;
}
.shape {
  width: 100%;
  height: 150px;
  background: linear-gradient(135deg, #4caf50 50%, transparent 50%);
}
.links {
  margin-top: 20px;
  text-align: center;
}
.links a {
  color: #4caf50;
  text-decoration: none;
  margin: 0 8px;
  font-weight: bold;
}
.links a:hover {
  text-decoration: underline;
}

.new-image {
  display: block;
  width: 100%;
  height: 100%;
  min-height: 200px;
  object-fit: cover;
  border-radius: 5px;
}
.new-card {
  margin: 20px 0;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
